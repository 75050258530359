<template>
  <b-row>
    <b-col>
      <b-card title="Generate NPP">
        <b-table :items="items" :fields="fields" responsive striped>
          <template #cell(no)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(employee)="data">
            {{ data.item.employee }} / {{ data.item.name }}
          </template>
          <template #cell(daily_money)="data">
            {{ data.item.daily_money | currency }}
          </template>
          <template #cell(total_accomodation)="data">
            {{ data.item.total_accomodation | currency }}
          </template>
          <template #cell(total_transportation)="data">
            {{ data.item.total_transportation | currency }}
          </template>
          <template #cell(total_cost)="data">
            {{
              (data.item.daily_money +
                data.item.total_accomodation +
                data.item.total_transportation)
                | currency
            }}
          </template>
        </b-table>
        <b-card-footer align="right">
          <b-button variant="secondary" @click="goBack()">Batal</b-button>
          <b-button
            v-if="items.length"
            variant="primary"
            class="ml-1"
            @click="save()"
          >
            Simpan
          </b-button>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard, BTable, BCardFooter, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BCardFooter,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      items: [],
      fields: [
        'no',
        { key: 'employee', label: 'NIPPOS / Nama' },
        { key: 'sppd_number', label: 'Nomor SPPD' },
        { key: 'daily_money', label: 'Uang Harian' },
        { key: 'total_accomodation', label: 'Biaya Akomodasi' },
        { key: 'total_transportation', label: 'Biaya Transportasi' },
        { key: 'total_cost', label: 'Total Biaya' },
      ],
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      axios.get('/npps/preview').then(res => {
        this.items = res.data.data
      })
    },
    goBack() {
      this.$router.back()
    },
    save() {
      axios.post('/npps').then(() => {
        this.$router.push({
          name: 'manajemen-npp',
          query: {
            event: 'success',
            title: 'Berhasil',
            text: 'NPP berhasil digenerate',
          },
        })
      })
    },
  },
}
</script>

<style></style>
